import React, { useState } from 'react'
import emailjs from 'emailjs-com';
import './ReviewsCust.scss'
import { Helmet } from "react-helmet";
import NavigatorPath from "../../Tools/NavigatorPath/NavigatorPath"
import { useNavigate } from 'react-router-dom';
import Chimney3 from '../../Images/Chimney3.jpeg'
import bgnobgs from '../../Images/bgnobgs.png'

export default function ReviewsCust() {
    const navigate = useNavigate()


    return (
        <div className="ReviewsCust">
            <Helmet>
                {/* <!-- TITLE -->  */}
                {/* <!-- Maximum of 65 characters --> */}
                <title>Customer Reviews | Pasadena Chimney Sweep - Hear What Our Clients Say</title>
                {/* <!-- Maximum 35 characters --> */}
                <meta name="title" property="title" content="Customer Reviews | Pasadena Chimney Sweep - Hear What Our Clients Say" data-react-helmet="true" />
                <meta name="og:title" property="og:title" content="Customer Reviews | Pasadena Chimney Sweep - Hear What Our Clients Say" data-react-helmet="true" />
                {/* <!-- META --> */}
                <meta name="og:locale" property="og:locale" content="en_US" data-react-helmet="true" />
                <meta name="og:type" property="og:type" content="article" data-react-helmet="true" />
                <meta name="og:image" property="og:image" content="article" data-react-helmet="true" />
                {/* <!-- Full link to the current webpage address -->  */}
                <meta name="og:url" property="og:url" content="https://www.chimneysweeppasadena.org/Reviews by our customers" data-react-helmet="true" />
                {/* <!-- Maximum of 155 characters  --> */}
                <meta name="description" content="Read customer reviews and testimonials to see why Pasadena Chimney Sweep is trusted for chimney cleaning, inspections, and repairs. Discover the experiences of our satisfied clients." data-react-helmet="true" />
                {/* <!-- Maximum 65 characters --> */}
                <meta name="og:description" property="og:description" content="Read customer reviews and testimonials to see why Pasadena Chimney Sweep is trusted for chimney cleaning, inspections, and repairs. Discover the experiences of our satisfied clients." data-react-helmet="true" />
                <meta name="og:site_name" property="og:site_name" content="Customer Reviews | Pasadena Chimney Sweep - Hear What Our Clients Say" data-react-helmet="true" />
                <meta name="keywords" content="chimney sweep, chimney Inspection, Chimney Inspection, Fireplace Inspection, Fireplace Cleaning, Chimney Cleaning, Chimney Maintenance, Fireplace Maintenance, Dryer Vent Cleaning, Chimney Inspection, Fireplace Inspection, Fireplace Cleaning, Chimney Cleaning, Chimney Maintenance, Fireplace Maintenance, Dryer Vent Cleaning, chimney services, chimney service, Chimney Inspection, Fireplace Inspection, Fireplace Cleaning, Chimney Cleaning, Chimney Maintenance, Fireplace Maintenance, Dryer Vent Cleaning, Chimney Insulation, Chimney Installation, Chimney Liner Repair, Chimney Pointing, Chimney Rain Cap Installation, Chimney Repair, Chimney Crown Repair, Chimney Restoration, Chimney Flue Installation, Chimney Flue Repair, ​Chimney Construction, Chimney Vent Installation, Chimney Flashing, Flexible Chimney Liner Installation, Chimney Damper Repair, Chimney Cap Repair, Fireplace Insulation, Fireplace Installation, Fireplace Liner Repair, Fireplace Pointing, Fireplace Rain Cap Installation, Fireplace Repair, Fireplace Crown Repair, Fireplace Restoration, Fireplace Flue Installation, Fireplace Flue Repair, Ffireplace Construction, Fireplace Vent Installation, Fireplace Flashing, Flexible fireplace Liner Installation, Fireplace Damper Repair, Fireplace Cap, Blog" data-react-helmet="true" />
            </Helmet>
            <div className="ReviewsCustTitle">
                <h1>What Our Customers Are Saying</h1>
                <h2>Find out why Pasadena Chimney Sweep is the nation’s go-to chimney company. As industry leaders in chimney service and repair, we manage everything from routine installations and maintenance to urgent emergencies requiring immediate attention. Our expert plumbers are available around the clock, including weekends and holidays, ensuring your home’s chimney operates smoothly year-round. Trust the professionals at Pasadena Chimney Sweep to keep your chimney system in top shape. For reliable service you can count on, contact Pasadena Chimney Sweep today for all your chimney needs.</h2>
            </div>
            <div className="ReviewsCustLsit">


                <div className="ReviewsCustBox">
                    <h3>⭐ ⭐ ⭐ ⭐ ⭐</h3>
                    <h2>"Pasadena Chimney Sweep did an outstanding job on my chimney. They were thorough and explained everything clearly. I highly recommend their <a href="/service/Chimney-Inspection">chimney inspection</a> services."</h2>
                    <h4>- John M.</h4>
                </div>
                <div className="ReviewsCustBox">
                    <h3>⭐ ⭐ ⭐ ⭐ ⭐</h3>
                    <h2>"The team at Pasadena Chimney Sweep was punctual, professional, and did a great job with the <a href="/service/Chimney-Cleaning">chimney cleaning</a>. My fireplace is working perfectly now."</h2>
                    <h4>- Sarah T.</h4>
                </div>
                <div className="ReviewsCustBox">
                    <h3>⭐ ⭐ ⭐ ⭐ ⭐</h3>
                    <h2>"I was impressed with the attention to detail during my <a href="/service/Chimney-Maintenance">chimney maintenance</a>. They found a minor issue and fixed it on the spot. Excellent service!"</h2>
                    <h4>- David R.</h4>
                </div>
                <div className="ReviewsCustBox">
                    <h3>⭐ ⭐ ⭐ ⭐ ⭐</h3>
                    <h2>"Great experience with Pasadena Chimney Sweep. They handled my <a href="/service/Chimney-Repair">chimney repair</a> efficiently, and the pricing was fair. Highly recommend!"</h2>
                    <h4>- Emily K.</h4>
                </div>
                <div className="ReviewsCustBox">
                    <h3>⭐ ⭐ ⭐ ⭐ ⭐</h3>
                    <h2>"Professional and reliable! They did an excellent job on my <a href="/service/Creosote-Removal">creosote removal</a>. My chimney is now safe and clean."</h2>
                    <h4>- Mark S.</h4>
                </div>
                <div className="ReviewsCustBox">
                    <h3>⭐ ⭐ ⭐ ⭐ ⭐</h3>
                    <h2>"Pasadena Chimney Sweep was quick to respond to my inquiry and scheduled a <a href="/service/Chimney-Inspection">chimney inspection</a> right away. Their service was top-notch!"</h2>
                    <h4>- Linda H.</h4>
                </div>
                <div className="ReviewsCustBox">
                    <h3>⭐ ⭐ ⭐ ⭐ ⭐</h3>
                    <h2>"I had a great experience with their <a href="/service/Chimney-Waterproofing">chimney waterproofing</a> service. No more leaks, and the work was done professionally. Highly recommend!"</h2>
                    <h4>- Tom B.</h4>
                </div>
                <div className="ReviewsCustBox">
                    <h3>⭐ ⭐ ⭐ ⭐ ⭐</h3>
                    <h2>"The team did an excellent job with my <a href="/service/Fireplace-Cleaning">fireplace cleaning</a>. They were thorough and left the area spotless."</h2>
                    <h4>- Nancy L.</h4>
                </div>
                <div className="ReviewsCustBox">
                    <h3>⭐ ⭐ ⭐ ⭐ ⭐</h3>
                    <h2>"Pasadena Chimney Sweep repaired my chimney cap efficiently. I’m very satisfied with their <a href="/service/Chimney-Cap-Installation">chimney cap installation</a> service."</h2>
                    <h4>- Alex J.</h4>
                </div>
                <div className="ReviewsCustBox">
                    <h3>⭐ ⭐ ⭐ ⭐ ⭐</h3>
                    <h2>"I highly recommend their <a href="/service/Chimney-Liner-Installation">chimney liner installation</a> service. The team was knowledgeable, and the installation was flawless."</h2>
                    <h4>- Rachel P.</h4>
                </div>
                <div className="ReviewsCustBox">
                    <h3>⭐ ⭐ ⭐ ⭐ ⭐</h3>
                    <h2>"Pasadena Chimney Sweep provided excellent <a href="/service/Smoke-Chamber-Repair">smoke chamber repair</a> services. They were quick, efficient, and professional."</h2>
                    <h4>- Michael C.</h4>
                </div>
                <div className="ReviewsCustBox">
                    <h3>⭐ ⭐ ⭐ ⭐ ⭐</h3>
                    <h2>"They did a fantastic job with my <a href="/service/Chimney-Crown-Repair">chimney crown repair</a>. I’m very happy with the results!"</h2>
                    <h4>- Sophia W.</h4>
                </div>
                <div className="ReviewsCustBox">
                    <h3>⭐ ⭐ ⭐ ⭐ ⭐</h3>
                    <h2>"I had a great experience with their <a href="/service/Chimney-Repointing">chimney repointing</a> service. The team was professional and did an excellent job."</h2>
                    <h4>- James D.</h4>
                </div>
                <div className="ReviewsCustBox">
                    <h3>⭐ ⭐ ⭐ ⭐ ⭐</h3>
                    <h2>"Pasadena Chimney Sweep fixed a leak in my chimney. Their <a href="/service/Chimney-Leak-Detection">chimney leak detection</a> and repair service was superb."</h2>
                    <h4>- Olivia K.</h4>
                </div>
                <div className="ReviewsCustBox">
                    <h3>⭐ ⭐ ⭐ ⭐ ⭐</h3>
                    <h2>"The team was friendly, professional, and efficient. I highly recommend their <a href="/service/Chimney-Animal-Removal">chimney animal removal</a> service."</h2>
                    <h4>- Chris F.</h4>
                </div>
                <div className="ReviewsCustBox">
                    <h3>⭐ ⭐ ⭐ ⭐ ⭐</h3>
                    <h2>"Pasadena Chimney Sweep was quick to eliminate the odors from my chimney. Their <a href="/service/Chimney-Odor-Elimination">chimney odor elimination</a> service was very effective."</h2>
                    <h4>- Victoria G.</h4>
                </div>
                <div className="ReviewsCustBox">
                    <h3>⭐ ⭐ ⭐ ⭐ ⭐</h3>
                    <h2>"They solved my chimney draft issues perfectly. I’m very satisfied with their <a href="/service/Chimney-Draft-Issues">chimney draft issue</a> service."</h2>
                    <h4>- Daniel H.</h4>
                </div>
                <div className="ReviewsCustBox">
                    <h3>⭐ ⭐ ⭐ ⭐ ⭐</h3>
                    <h2>"Pasadena Chimney Sweep did an excellent job repairing my fireplace damper. Their <a href="/service/Fireplace-Damper-Repair">fireplace damper repair</a> service was top-notch."</h2>
                    <h4>- Laura B.</h4>
                </div>
                <div className="ReviewsCustBox">
                    <h3>⭐ ⭐ ⭐ ⭐ ⭐</h3>
                    <h2>"I was impressed with their attention to detail during my <a href="/service/Chimney-Safety-Inspections">chimney safety inspection</a>. They caught issues I wasn’t aware of!"</h2>
                    <h4>- Steven M.</h4>
                </div>
                <div className="ReviewsCustBox">
                    <h3>⭐ ⭐ ⭐ ⭐ ⭐</h3>
                    <h2>"Pasadena Chimney Sweep cleaned my fireplace and made it look brand new. I highly recommend their <a href="/service/Fireplace-Cleaning">fireplace cleaning</a> service."</h2>
                    <h4>- Ava P.</h4>
                </div>
                <div className="ReviewsCustBox">
                    <h3>⭐ ⭐ ⭐ ⭐ ⭐</h3>
                    <h2>"I had a great experience with their <a href="/service/Chimney-Maintenance">chimney maintenance</a> service. The team was professional and thorough."</h2>
                    <h4>- Henry T.</h4>
                </div>
                <div className="ReviewsCustBox">
                    <h3>⭐ ⭐ ⭐ ⭐ ⭐</h3>
                    <h2>"They did a fantastic job with my <a href="/service/Chimney-Relining">chimney relining</a>. I’m very happy with the service and the results."</h2>
                    <h4>- Emma L.</h4>
                </div>
                <div className="ReviewsCustBox">
                    <h3>⭐ ⭐ ⭐ ⭐ ⭐</h3>
                    <h2>"Pasadena Chimney Sweep provided excellent service during my <a href="/service/Chimney-Cleaning">chimney cleaning</a>. They were quick and left no mess behind."</h2>
                    <h4>- Liam W.</h4>
                </div>
                <div className="ReviewsCustBox">
                    <h3>⭐ ⭐ ⭐ ⭐ ⭐</h3>
                    <h2>"The team was very knowledgeable and did a great job with my <a href="/service/Chimney-Inspection">chimney inspection</a>. I feel much safer using my fireplace now."</h2>
                    <h4>- Isabella M.</h4>
                </div>
                <div className="ReviewsCustBox">
                    <h3>⭐ ⭐ ⭐ ⭐ ⭐</h3>
                    <h2>"Pasadena Chimney Sweep was professional and efficient during my <a href="/service/Chimney-Repair">chimney repair</a>. The results were excellent!"</h2>
                    <h4>- Ethan S.</h4>
                </div>
                <div className="ReviewsCustBox">
                    <h3>⭐ ⭐ ⭐ ⭐ ⭐</h3>
                    <h2>"Their <a href="/service/Chimney-Waterproofing">chimney waterproofing</a> service was excellent. No more leaks, and the work was done quickly and professionally."</h2>
                    <h4>- Sophia R.</h4>
                </div>
                <div className="ReviewsCustBox">
                    <h3>⭐ ⭐ ⭐ ⭐ ⭐</h3>
                    <h2>"I’m very pleased with their <a href="/service/Chimney-Relining">chimney relining</a> service. The team was professional, and the work was done to a high standard."</h2>
                    <h4>- Benjamin A.</h4>
                </div>
                <div className="ReviewsCustBox">
                    <h3>⭐ ⭐ ⭐ ⭐ ⭐</h3>
                    <h2>"Pasadena Chimney Sweep did an amazing job with my <a href="/service/Chimney-Crown-Repair">chimney crown repair</a>. I highly recommend their services."</h2>
                    <h4>- Amelia T.</h4>
                </div>
                <div className="ReviewsCustBox">
                    <h3>⭐ ⭐ ⭐ ⭐ ⭐</h3>
                    <h2>"I had a great experience with their <a href="/service/Chimney-Cap-Installation">chimney cap installation</a> service. The team was knowledgeable and efficient."</h2>
                    <h4>- Lucas J.</h4>
                </div>
                <div className="ReviewsCustBox">
                    <h3>⭐ ⭐ ⭐ ⭐ ⭐</h3>
                    <h2>"Pasadena Chimney Sweep was quick to respond and did an excellent job with my <a href="/service/Chimney-Cleaning">chimney cleaning</a>. I highly recommend them!"</h2>
                    <h4>- Mia G.</h4>
                </div>
            </div>

            <NavigatorPath />

        </div>
    )
}

